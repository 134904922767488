import Vue from "vue";

/*TITLE*/
document.title = "Edificio Observatorio | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Edificio Observatorio";
Vue.prototype.$subtitle = "Diseño que desprende elegancia y modernidad. Interiores diseñados con acabados de primera calidad.";

/*INTRO*/
Vue.prototype.$promoter = "Edificio Observatorio";
Vue.prototype.$introSubtitle = "Diseño que desprende elegancia y modernidad. Interiores diseñados con acabados de primera calidad.";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-ac4-style-quiet--20230113080157.jpg";
Vue.prototype.$image_kitchen = "cocina-rhin-natural-barcelona-e-frente-blanco-nubol-encimera-compac-plomo-alfombra-muebles--20230113080138.jpg";
Vue.prototype.$image_bath1 = "bano-1-mirage-dark-bottega-caliza-deco-rodano--20230113080101.jpg";
Vue.prototype.$image_bath2 = "bano-2-mirage-dark-bottega-caliza-deco-rodano--20230113080109.jpg";
Vue.prototype.$image_room = "habpral-ac4-style-quiet--20230113080158.jpg";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-ac4-style-quiet--20230113080157.jpg",
  },
  {
    src: "salon-ac4-residence-1l-arizona--20230113080105.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "cocina-rhin-natural-barcelona-e-frente-blanco-nubol-encimera-compac-plomo-alfombra-muebles--20230113080138.jpg",
  },
  {
    src: "cocina-rhin-natural-barcelona-e-frente-blanco-nubol-encimera-compac-plomo-alfombra-mesa--20230113080145.jpg",
  },
  {
    src: "cocina-rhin-natural-barcelona-e-frente-blanco-nubol-encimera-blanco-nubol--20230113080153.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "bano-1-mirage-dark-bottega-caliza-deco-rodano--20230113080101.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "bano-2-mirage-dark-bottega-caliza-deco-rodano--20230113080109.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
  {
    src: "habpral-ac4-style-quiet--20230113080158.jpg",
  },
  {
    src: "habpral-ac4-style-calm--20230113080104.jpg",
  },
  {
    src: "habpral-ac4-residence-1l-arizona--20230113080108.jpg",
  },
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "6";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://360.porcelanosapartners.com/EDIFICIO_OBSERVATORIO/";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://www.youtube.com/embed/TjMgaw4--WA";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20arenal%203%20san%20fernando%20cádiz",
    text: "Calle Arenal, 3. San Fernando, Cádiz",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20arenal%203%20san%20fernando%20cádiz",
    text: "Calle Arenal, 3. San Fernando, Cádiz",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:647402004",
    text: "647402004",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@promocioneshermanoslahule.com",
    text: "info@promocioneshermanoslahule.com",
  },
];
